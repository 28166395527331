<template>
  <m-app-bar-compact></m-app-bar-compact>
  <div class="px-4 pt-4 pb-14">
    <div class="mb-6 flex justify-between">
      <div class="flex min-w-0 flex-col">
        <h1 class="font-bold text-2xl truncate">
          {{ user.name }} {{ user.surname }}
        </h1>
        <p class="text-sm font-medium text-gray-500 truncate">
          #{{ user.login }}
        </p>
      </div>
      <div
        class="flex origin-top-right rounded-full w-16 h-16 bg-blue-500 ml-4"
        style="min-width: 64px; min-height: 64px;"
      >
        <span class="m-auto text-4xl text-white">{{ firstLetter }}</span>
      </div>
    </div>

    <div class="w-full h-px bg-gray-200"></div>

    <div class="mt-6">
      <h3 class="font-bold text-lg mb-2">Дети</h3>

      <div class="flex flex-col">
        <div v-for="kid in kids" :key="kid.id" class="flex items-center py-2">
          <div class="flex w-10 h-10 rounded bg-blue-500 mr-4">
            <span class="m-auto text-lg font-medium text-white">
              {{ kid.name[0] }}
            </span>
          </div>
          <div class="flex flex-col">
            <p class="font-medium text-base leading-none">{{ kid.name }}</p>
            <p class="text-xs text-gray-500 font-medium">
              {{ kid.description }}
            </p>
          </div>
        </div>

        <div class="w-4 h-4 flex"></div>
      </div>

      <div class="mt-4">
        <h3 class="font-bold text-lg mb-2">Приложение</h3>

        <ul class="-mx-4">
          <!--          <li class="flex px-4 h-12 items-center text-sm">-->
          <!--            Настройки-->
          <!--          </li>-->
          <!--          <li class="flex px-4 h-12 items-center text-sm">-->
          <!--            Служба поддержки-->
          <!--          </li>-->
          <li class="flex px-4 h-12 items-center text-sm">
            О приложении
          </li>
        </ul>
      </div>

      <p class="text-xs text-gray-500 w-full py-4 text-center">П11-2</p>

      <m-button @click="logout" class="mt-4" full large>Выйти</m-button>
    </div>

    <m-bottom-navigation />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { useSession } from "@/store/session.store";
import { useKids } from "@/store/kids.store";

import MBottomNavigation from "@/components/ui/mobile/bottom-navigation/MNavigation.vue";
import MAppBarCompact from "@/components/ui/mobile/app-bar/MAppBarCompact.vue";
import MButton from "@/components/ui/mobile/buttons/MButton.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "MProfile",
  components: { MButton, MAppBarCompact, MBottomNavigation },

  setup() {
    const session = useSession();
    const kids = useKids();
    const router = useRouter();

    const firstLetter = computed(() => {
      const SESSION = sessionStorage.getItem("SESSION");
      const profile = JSON.parse(SESSION || "{name: '0'}");
      return profile.name[0];
    });

    const logout = async () => {
      try {
        await session.logout();
        await router.push({ name: "login" });
      } catch {
        //  PASS
      }
    };

    return {
      firstLetter,
      user: session.user,
      kids: kids.kids,
      logout
    };
  }
});
</script>
