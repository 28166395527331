
import { computed, defineComponent } from "vue";

import { useSession } from "@/store/session.store";
import { useKids } from "@/store/kids.store";

import MBottomNavigation from "@/components/ui/mobile/bottom-navigation/MNavigation.vue";
import MAppBarCompact from "@/components/ui/mobile/app-bar/MAppBarCompact.vue";
import MButton from "@/components/ui/mobile/buttons/MButton.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "MProfile",
  components: { MButton, MAppBarCompact, MBottomNavigation },

  setup() {
    const session = useSession();
    const kids = useKids();
    const router = useRouter();

    const firstLetter = computed(() => {
      const SESSION = sessionStorage.getItem("SESSION");
      const profile = JSON.parse(SESSION || "{name: '0'}");
      return profile.name[0];
    });

    const logout = async () => {
      try {
        await session.logout();
        await router.push({ name: "login" });
      } catch {
        //  PASS
      }
    };

    return {
      firstLetter,
      user: session.user,
      kids: kids.kids,
      logout
    };
  }
});
